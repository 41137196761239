import { contentRequestTypes } from "../../modules/messages/Const";
import { textLengths } from "../../redux/Types";

export const ValidateContentRequest = (CRData) => {
  let errors = {};
  if (CRData) {
    if(!CRData.type || CRData.type === null){
      errors.type = "Should not be empty";
    }
    if(!CRData.proposedUsage || CRData.proposedUsage === null){
      errors.proposedUsage = "Should not be empty";
    }
    
    if (CRData.type && CRData.type === contentRequestTypes.ALBUM) {
      if (!CRData.albumTitle || CRData.albumTitle == "") {
        errors.albumTitle = "Should not be empty";
      } else if (
        CRData.albumTitle &&
        CRData.albumTitle.length > textLengths.TEXT
      ) {
        errors.albumTitle =
          "Sorry, the title you entered is too long. Please try a shorter title";
      }

      if (!CRData.albumArtist || CRData.albumArtist == "") {
        errors.albumArtist = "Should not be empty";
      } else if (
        CRData.albumArtist &&
        CRData.albumArtist.length > textLengths.TEXT
      ) {
        errors.albumArtist =
          "Sorry, the name you entered is too long. Please try a shorter artist name";
      }
      // if (!CRData.notes || CRData.notes == "") {
      //     errors.notes = "Should not be empty";
      // }
      if (CRData.notes && CRData.notes.length > textLengths.MEDIUMTEXT) {
        errors.notes =
          "Sorry, the note you entered is too long. Please try a shorter note";
      }

      if (CRData.catNumber && CRData.catNumber.length > textLengths.TEXT) {
        errors.catNumber =
          "Sorry, the catalog# you entered is too long. Please try a shorter catalog#";
      }
      if (CRData.recordLabel && CRData.recordLabel.length > textLengths.TEXT) {
        errors.recordLabel =
          "Sorry, the record label you entered is too long. Please try a shorter record label";
      }
      if (CRData.publisher && CRData.publisher.length > textLengths.TEXT) {
        errors.publisher =
          "Sorry, the publisher you entered is too long. Please try a shorter publisher";
      }
      if (CRData.distributor && CRData.distributor.length > textLengths.TEXT) {
        errors.distributor =
          "Sorry, the distributor name you entered is too long. Please try a shorter distributor name";
      }
    }
    else {
    // else if(CRData.type) {
      if (!CRData.trackTitle || CRData.trackTitle == "") {
        errors.trackTitle = "Should not be empty";
      } else if (
        CRData.trackTitle &&
        CRData.trackTitle.length > textLengths.TEXT
      ) {
        errors.trackTitle =
          "Sorry, the title you entered is too long. Please try a shorter title";
      }

      if (!CRData.trackArtist || CRData.trackArtist == "") {
        errors.trackArtist = "Should not be empty";
      } else if (
        CRData.trackArtist &&
        CRData.trackArtist.length > textLengths.TEXT
      ) {
        errors.trackArtist =
          "Sorry, the name you entered is too long. Please try a shorter artist name";
      }
      // if (!CRData.notes || CRData.notes == "") {
      //     errors.notes = "Should not be empty";
      // }
      if (CRData.notes && CRData.notes.length > textLengths.MEDIUMTEXT) {
        errors.notes =
          "Sorry, the note you entered is too long. Please try a shorter note";
      }
      if (CRData.catNumber && CRData.catNumber.length > textLengths.TEXT) {
        errors.catNumber =
          "Sorry, the catalog# you entered is too long. Please try a shorter catalog#";
      }
      if (CRData.album && CRData.album.length > textLengths.TEXT) {
        errors.album =
          "Sorry, the album you entered is too long. Please try a shorter album";
      }
      if (CRData.recordLabel && CRData.recordLabel.length > textLengths.TEXT) {
        errors.recordLabel =
          "Sorry, the record label you entered is too long. Please try a shorter record label";
      }
      if (CRData.publisher && CRData.publisher.length > textLengths.TEXT) {
        errors.publisher =
          "Sorry, the publisher you entered is too long. Please try a shorter publisher";
      }
      if (CRData.altTrackTitle && CRData.altTrackTitle.length > textLengths.TEXT) {
        errors.altTrackTitle =
          "Sorry, the alternate track title you entered is too long. Please try a shorter alternate track title";
      }
      if (CRData.distributor && CRData.distributor.length > textLengths.TEXT) {
        errors.distributor =
          "Sorry, the distributor Name you entered is too long. Please try a shorter distributor Name";
      }
    }

  }

  if (Object.entries(errors).length === 0) {
    return null;
  } else {
    return errors;
  }
};
