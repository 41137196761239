export default {
appbarActionMenu: {
    height: "21px",
    width: "21px",
    position: "relative",
    top: "-1px",
    minWidth: "20px",
    marginLeft: "5px",
},

appbarActionButton: {
    background: "#0C853E",
    boxShadow: "none",
    color: "#fff",
    textTransform: "none",
    fontWeight: "400",
    margin: "0px 0px 0px 5px",
    padding:"5px 16px 4px 16px",
    fontSize:"0.75em",
    minWidth:"80px",
    paddingRight: "8px",
    border:" 1px solid #005026",
    "&:hover": {
        background: "#000",
        color: "#29D211",
        border:" 1px solid #0C853E",
    },
},
appbarAddButton: {
    background: "#0c853e",
    boxShadow: "none",
    color: "#fff",
    textTransform: "none",
    fontWeight: "400",
    margin: "0px 5px",
    paddingRight: "15px !important",
    fontSize:"0.75em",
    border: "1px solid #13924f",
    padding:"4px  10px",
    "&:hover": {
        background: "#000",
        color: "#29D211",
    },
    "& svg":{
      fontSize: "19px",
      marginRight:"5px",
    },
},
appbarRefreshButton: {
    border: "none",
    background: "#008135",
    verticalAlign: "top",
    border: "1px solid #008135",
    "&:hover": {
        background: "#04923F",
        color: "#fff",
    },
    margin: "0 5px",
    padding: "5px",
    minWidth: "34px",
    height: "32px",
    lineHeight: "19px",
    boxShadow: "none",
},
appbarResultConfigButton:{
  marginLeft:"5px !important",
},
appbarTagButton: { 
  color: "#fff",
  verticalAlign: "top",
  background: "#00a5ef",
  border: "1px solid #00a5ef",
  "&.Mui-disabled":{
    background: "#212020",
    color: "#fff",
  },
  "&:hover": {
    background: "#05b2ff",
      color: "#fff",
  },
  margin: "0px 5px 0 5px",
  padding: "5px",
  minWidth: "34px",
  height: "32px",
  lineHeight: "19px",
  boxShadow: "none",
},
tagCloudClosed: {
  color: "#00a5ef", 
  background: "#212020",
  border: "1px solid #00a5ef",
  verticalAlign: "top",
  "&:hover": {
      background: "#048cca",
      color: "#37c1ff",
  },
  margin: "0px 5px 0 5px",
  padding: "5px",
  minWidth: "34px",
  height: "32px",
  lineHeight: "19px",
  boxShadow: "none",
  
},
playlistSearchIconWithoutLink:{
  fontSize:"35px",
  height: "30px",
  margin: "0 4px",
},
commonBackButton:{
  border: "none",
  background: "#676767",
  verticalAlign: "middle",
  "&:hover": {
      background: "#464646",
      color: "#fff",
      boxShadow:"none"
        },
        "& svg": {
          fontSize:"22px"
      },
    "& .MuiAvatar-img":{
      borderRadius: "50%",
      width: "40px",
      height: "40px",
    },
    margin: "0px 5px 0 5px",
    padding: "2px",
    minWidth: "inherit",
    height: "26px",
    lineHeight: "19px",
    boxShadow: "none",    
},

playlistBackButton: {
  border: "none",
  background: "#676767",
  verticalAlign: "middle",
  "&:hover": {
      background: "#333333",
      color: "#fff",
      boxShadow:"none"
        },
        "& svg": {
          fontSize:"22px"
      },
    "& .MuiAvatar-img":{
      borderRadius: "50%",
      width: "40px",
      height: "40px",
    },
    margin: "0px",
    padding: "2px",
    minWidth: "inherit",
    height: "26px",
    lineHeight: "19px",
    boxShadow: "none",     
  },
  genreSelectBox:{
    margin:"0 0px 0 10px",
    maxWidth: "200px",
  },
  clearanceSaveButton:{
    minWidth:"inherit",
    marginLeft:"10px",
  },
  clearanceEditTitle:{
    display:"flex",
    alignItems: "end",
    marginBottom: "10px",
    "& .MuiTextField-root":{
      minWidth:"250px",
    },
    "& input":{
      background:"#1A5974",
    },
    "& .MuiSelect-selectMenu": {
      paddingRight:" 32px",
      background: "#19688a",
      paddingTop: "7px",
      paddingBottom: "6px",
      textAlign:"left", 
    },
    "& label.genreLableSpan":{
      whiteSpace: "nowrap",
    margin: "10px 0px 0 10px",
    alignItems: "inherit",
    textTransform: "uppercase",
    fontSize: "11px",
    }
  },
  clearanceBackButton: {
    border: "none",
    background: "#00739c",
    verticalAlign: "middle",
    float: "left",
    marginRight: "10px",
    "&:hover": {
        background: "#042b3c",
        color: "#fff",
        boxShadow:"none"
          },
          "& svg": {
            fontSize:"22px"
        },
      "& .MuiAvatar-img":{
        borderRadius: "50%",
        width: "40px",
        height: "40px",
      },
      margin: "0px",
      padding: "2px",
      minWidth: "inherit",
      height: "26px",
      lineHeight: "19px",
      boxShadow: "none",     
    },
  sessionBackButton: {
    border: "none",
    background: "#325f8a",
    verticalAlign: "middle",
    width:"25px",
    "&:hover": {
        background: "#467eb3",
        color: "#fff",
        boxShadow:"none"
          },
          "& svg": {
            fontSize:"22px"
        },
      "& .MuiAvatar-img":{
        borderRadius: "50%",
        width: "40px",
        height: "40px",
      },
      margin: "0px",
      padding: "2px",
      minWidth: "inherit",
      height: "26px",
      lineHeight: "19px",
      boxShadow: "none",   
      marginRight: "10px",
    },
  playlistHeadWrapper:{
    display:"flex",
    alignItems:"center",
    "& .activeChart": {
      marginRight: "10px",
      verticalAlign: "middle",
      height: "23px",
  }
  },
  playlistHeaderImage: {
      borderRadius: "3px",
        width: "40px",
        minWidth: "40px",
        margin: "0px 15px 0px 15px",
        overflow:"hidden",
        height: "40px",   
        "& img":{      
          width: "100%",
      
        },     
    },
  playlistHeaderTitle:{
    fontSize:"19px",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // maxWidth: "350px",
    display: "inline-block",
    textOverflow: "ellipsis",
  },
  playlistHeaderTitleAddedMargin:{
    marginLeft:"15px",
  },
  playlistSearchBox:{
    textAlign:"left",
    display:"flex",
    alignItems:"end",
  },
  playlistSearchInside:{
    textAlign:"left",
    display:"flex",
    alignItems:"center",
    justifyContent: "flex-end",
  },
playlistSearchBar:{
    float: "left",
    padding: "3px 5px",
     fontSize:"1em",
    width: "-webkit-fill-available",
    width:"100%",
    marginRight: "5px",
    maxWidth: "300px",
    borderRadius: "5px",
    background:"#636363",
    "& div:before": {
      outline: "none",
      border: "none !important",
      width: "100%",
  },
  "& .MuiInput-underline:after": {
      borderBottom: "2px solid #0C853E",
  },
  "& div": {
      width: "100%",
  },
  "& input": {
      width: "100%",
      padding: "5px 8px",
       fontSize:"0.8em",
      fontWeight: "300",
  },
},
appbarSaveSearchButton: {
    border: "none",
    background: "#008135",
    float:"left",
    verticalAlign: "top",
    "&:hover": {
        background: "#04923F",
        color: "#fff",
    },
    margin: "0 5px",
    padding: "5px",
    minWidth: "34px",
    height: "32px",
    lineHeight: "19px",
    boxShadow: "none", 
},
appbarSettingButton:{
  background: "#008135",
  padding: "5px 14px !important",
  width: "inherit !important",
    background: "#008135 !important",
  "&:hover": {
      background: "#000 !important",
      color: "#29D211",
  },
  "& svg": {
    fontSize:"18px",
    marginRight: "10px !important",
},
  margin: "0 5px",
  padding: "5px",
  minWidth: "34px",
  height: "32px",
  lineHeight: "19px",
  boxShadow: "none", 
},
downloadButton:{
  background: "#008135",
  padding: "5px 14px !important",
  width: "inherit !important",
    background: "#008135",
  "&:hover": {
      background: "#04923F",
      color: "#fff",
  },
  "& svg": {
    fontSize:"15px",
    margin:"0"
},
  margin: "0 0 0 5px",
  padding: "5px 15px",
  minWidth: "34px",
  height: "32px",
  lineHeight: "19px",
  boxShadow: "none", 
  fontSize:"0.8em",
  textTransform: "none",
  fontWeight: "400",

},
appbarHideLabel: {
    color: "#fff ",
    fontSize: "11px",
    padding: "5px 4px 0px 12px",
    display: "block",
    position: "absolute",
    overflow: "hidden",
    height: "1px",
    width: "1px",
    clip: "rect(1px, 1px, 1px, 1px)",
},

appbarWrap: {
    textAlign: "right",
},
appbarAlignRight:{
  textAlign:"right",
},
    
appbarFormWrap: {
    background: "#474747",
    padding: "10px",
    position: "absolute",
    zIndex: "3",
    width: "-webkit-calc(100% - 20px)",
    width: "calc(100% - 20px)",
    top: "0px",
    left: "0",
    overflow: "visible",
     fontSize:"1em",
    display: "flex",
},
appbarPlaylistWrap: {
  background: "#474747",
  padding: "10px",
  position: "absolute",
  zIndex: "3",
  width: "-webkit-calc(100% - 20px)",
  width: "calc(100% - 20px)",
  top: "0px",
  left: "0",
  overflow: "visible",
   fontSize:"1em",
  display: "flex",
},

toolsSaveButton:{
  border: "none",
  background: "#008135",
  verticalAlign: "top",
  fontSize:"12px",
  fontWeight:"400",
  textTransform:"none",
  "&:hover": {
      background: "#04923F",
      color: "#fff",
  },
  margin: "0 5px",
  padding: "5px 15px",
  minWidth: "34px",
  height: "32px",
  lineHeight: "19px",
  boxShadow: "none",
},
toolsArchiveIcon:{
    fontSize: "21px",
    marginRight: "10px",
    position: "relative",
    top: "-1px",
  },

// Advanced
toolsSideBar:{
    position:"relative",
    overflow:"hidden",
    height:"100%",

},

 
advancedSearchInput: {
    width:"100%",
    padding: "0",
    "& input": {
      borderRadius: "5px !important",
      padding: "10px",
       fontSize:"1em",
      background:"#292828"
    },
    "& div": {
      borderRadius: "5px !important",
      width:"100%"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
      borderColor: "#46af22 !important",
    },
    "& div.MuiSelect-selectMenu":{
      padding: "10px",
     fontSize:"1em",
    }
  },


userTabsList:{
          "& span svg":{
            display: "inline-block",
            verticalAlign: "middle",
            margin: "0 7px",
          }, 
          "& span":{
            display:"block",
          },           
          display:"flex",
},
panelHeaderClose:{
      background:"#4B4A4A",
      padding: "10px !important",
    width: "35px !important",
    minWidth: "inherit",
    fontSize: "13px !important",
    },
ReadOnlyLabel:{
      background: "#292929",
    padding: "10px",
    display: "block",
    borderRadius: "3px",
    fontSize: "14px",
    border: "1px solid #3b3b3b",
    overflow: "hidden",
    textOverflow: "ellipsis",
    },

// Users
userProfileImage: {
  padding: "20px",
  maxWidth: "100px",
  minWidth: "100px",
  minHeight: "100px",
  borderRadius:" 50% !important",
  marginBottom: "20px",
  margin:" 0 auto",
  background: "none",
  color: "#b9b9b9",
  border: "2px solid #757575",
  "& img":{
    borderRadius:" 90%",
  }
},
addedUsersName:{
  maxWidth:"250px",
  textOverflow:"ellipsis",
  overflow:"hidden",
  whiteSpace:"nowrap"
},
createdUsersName:{
  maxWidth:"150px",
  textOverflow:"ellipsis",
  overflow:"hidden",
  whiteSpace: "nowrap",
  maxWidth: "400px",
},
userNamePreview:{
  textOverflow:"ellipsis",
  overflow:"hidden",
  whiteSpace: "nowrap",
  maxWidth: "250px",
  margin: "0 auto",
  fontSize: "14px",
},
userNotes:{
  background:"#000",
  borderRadius:"3px",
  width: "97%",
  border: "1px solid #3b3b3b",
  color:"#fff",
  padding: "10px",
  "&:focus":{
    border:"1px solid #46af22",
    outline: "none",
  }
},
userName:{
  textAlign:"center",
  display: "block",
},
userTabsAll:{
  color:"#fff",
  "& .MuiTab-textColorPrimary.Mui-selected":{
    color:"#fff",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#279F04",
}
},
usersButtonsList:{
  textAlign:"right",
  padding: "0",
},
usersHeader:{
  display:"flex",
  background: "#292929",
  alignItems: "center",
},
usersProfileImage:{
  margin:"0 10px"
},

panelHeaderIcon:{
  "& svg":{
    fontSize: "35px",
    marginRight: "10px",
  },
},
roledividerMargin:{
  margin:"10px 10px  20px 10px ",
},

modulesSwitch:{
  fontSize:"14px",
  "& .Mui-checked":{
    color: "#0C853E",
  },
  "& .MuiSwitch-track":{
    backgroundColor: "#0C853E !important",
  }
  
},
panelHeader:{
  display: "flex",
  background: "#3e3e3e",
  padding:"0 15px",
  alignItems: "center",
  "& button":{
    verticalAlign: "middle",
    padding: "6px 8px",
    margin: "10px 5px 13px 10px",
    fontSize: "18px",
    background: "none",
    border: "1px solid #535353",
    borderRadius: "5px",
    color: "#fff",  
  },
  "& button:hover":{
    background:"#000",
    cursor:"pointer",
  },
  "& h2":{
    width: "-webkit-fill-available",
    width:"100%",
    fontSize: "1em",
    fontWeight: "500",
    whiteSpace: "nowrap",
    overflow: "hidden",
    // maxWidth: "330px",
    textOverflow: "ellipsis",
    "& svg":{
      fontSize: "28px",
      verticalAlign: "middle",
      marginRight: "10px",
    }
  },
  
},
appbarContainer:{
  padding:"10px",
  borderRadius:"0"
},
appbarSelect: {
  borderRadius: "4px !important",
  padding:" 0",
   fontSize:"1em",  
  background: "#5d5d5d",
  verticalAlign:"top",
  margin: "0 5px",
  "& input": {
    borderRadius: "5px !important",
    padding: "8.5px 14px 8.5px 31px",
     fontSize:"1em",
  },
  "& div": {
    padding: "6px 10px",
    borderRadius: "5px !important",
    fontSize: "0.75em",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline":{
    borderColor: "#46af22 !important",
    borderWidth: "1px!important",
  },
  "&.Mui-focused":{
    background:"#868686",
  },
  "&:hover":{
    background:"#868686",
  },
}, 
appbarHeaderWrap:{
  padding: "10px",
  background: "#212020",
  borderRadius: "4px",
},

toolsFieldWrapNotPersonal:{
  borderTop:"none",
  paddingTop: "10px",
  "& > div > div":{
    width: "25%",
    display:"inline-block",
    verticalAlign:"top"
  },
},
 
advancedFiterSingleWrap:{
  padding:"0 5px",
  width:"auto !important",
  marginBottom: "10px",
  display:"flex",
  "& .MuiCircularProgress-colorPrimary":{
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    right: "24px",
    top: "4px",
  },
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    paddingRight:" 32px",
    background: "#4A4949",
    paddingTop: "6px",
    paddingBottom: "6px",
    textAlign:"left", 
},

"& .MuiOutlinedInput-root .MuiSelect-iconOutlined":{
  right:"8px",
},  
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "#46af22 !important",
  }
},
advancedSearchTagActive:{
  clear: "left",
  marginTop: "10px !important",
  display:"flex !important",
},

advancedFiterMainWrap:{
  margin:"0 -5px",
  maxHeight:"178px",
  overflowY:"auto",
  overflowX: "hidden",
  paddingTop: "3px",

},
addtoPlaylistList:{
  padding:"5px 17px 0 17px",
  outline: "none",
   fontSize:"0.8em",
  color: "#26B70B",
},
contextMenuIcon:{ 
  color: "#fff",
  marginRight: "8px",
  minWidth: "20px",
},
addtoClearance:{
  padding:"5px 15px 0px 15px",
  outline: "none",
  fontSize:"0.8em",
  color: "#4dc6ff",
  // background: "#0a455f",
},
listOfClearance:{ 
  "& .MuiCircularProgress-colorPrimary":{
    color: "green", 
    width:"20px !important",
    height:"20px !important",
    margin:"5px 20px",
  },
},
circularProgress:{
  color: "green", 
  width:"20px !important",
  height:"20px !important",
  margin:"5px 20px",
},
addtoPlayout:{
  padding:"5px 15px 0px 15px",
  outline: "none",
   fontSize:"1em",
  color: "#37d9f1",
  // background: "#0a455f",
},


clearanceTrafficContainer:{
  textTransform:"uppercase",
},
clearanceTrafficLight:{
  width:"20px",
  height:"20px",
  borderRadius:"50%",
  margin: "0px 8px 0px 5px",
  display: "inline-block",
  verticalAlign: "middle",
},
recentPlaylistTopSearch:{
 
},
recentPlaylistItemsSearch:{
  padding:"0",
  width:"12.5rem",
  padding:" 10px 5px 10px 0 !important",
  "& input":{
    padding: "10.5px 14px",
    border: "none !important",
     fontSize:"0.8em",
    width: "100% !important",
    borderRadius: "3px",
  },
  "& .MuiOutlinedInput-notchedOutline":{
    border:"1px solid #03843a",
    borderTop: "0",
    borderRight: "0",
    borderLeft: "0",
    borderRadius: "3px",
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderTop: "0",
    borderRight: "0",
    borderLeft: "0",
    borderRadius: "3px",
    border:"1px solid #808080 !important"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
    border:"1px solid  #46af22 !important"
  },
  "& svg":{
    color: "#fff",
  }
},
recentClearanceSearch:{
  width:"12.5rem",
  padding:" 10px 5px 10px 0 !important",
  "& svg":{
    color:"#fff"
  },
  "& input":{
    padding: "10.5px 14px",
    border: "none !important",
     fontSize:"0.8em",
    width: "100% !important",
    borderRadius: "3px",
  },
  "& .MuiOutlinedInput-notchedOutline":{
    border:"1px solid #126d94",
    borderTop: "0",
    borderRight: "0",
    borderLeft: "0",
    borderRadius: "3px",
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderTop: "0",
    borderRight: "0",
    borderLeft: "0",
    borderRadius: "3px",
    border:"1px solid #808080   !important"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
    border:"1px solid #1582b1  !important"
  }
},
recentPlaylistItems:{
  color: "#26B70B",
  padding:"6px 14px",
  outline: "none",
  maxWidth: "232px",
  wordBreak: "break-word",
   fontSize:"1em",
  "& svg":{
    color:"#26B70B",
  },
  "&:hover":{
    color:"#7bef94",
    "& svg":{
      color:"#7bef94",
    }
  }
},
popMenuDark:{
  "& .MuiMenu-list .MuiListItem-button":{
    maxWidth: "232px",
    position: "relative",
    wordBreak: "break-word",
  },
  "& .MuiMenu-list":{
  background:"#333",
  "& hr":{
    border:"1px solid #565A5B",
  },
},
  
},
newClearanceIcons:{
  color: "#fff",
  marginRight:"8px",
  minWidth: "20px",
},
recentClearanceItems:{
  color: "#4dc6ff",
  padding:"6px 14px",
  outline: "none",
   fontSize:"1em",
  "& svg":{
    color:"#4dc6ff"
  },
  "&:hover":{
    color:"#81d7ff",
    "& svg":{
      color:"#81d7ff"
    }
  }
},
panelHeaderMultiText:{
  width:"-webkit-fill-available",
  width:"100%",
  margin:"5px 0",
  textAlign:"left",
  overflow: "hidden",
  "& h2":{
    marginBottom:"0",
    marginTop:"0px",
  },
  "& label":{
    fontSize: "14px !important",
    color: "#EBE8E8",
  }
},

SearchContainer:{
  display: "flex",
  width:"-webkit-fill-available",
  alignItems:" center",
  width:"100%",
  "& > div":{
    display: "flex",
  }
},

toolsMainSearchWrap:{
 float:"left"
},
underConstructionMessage:{
  padding:"10px",
  textAlign:"center",
  fontSize:"12px",
  background: "#9e9e9e",
  color: "#000",
  marginBottom: "20px",
  borderRadius: "3px",
},
noDataBorder:{
  border:"none"
},

navigationTitle:{
  fontSize:"0.8em",
},
navigationAccordianTitle:{
  margin: "0 !important",
  border:"none",
  boxShadow:"none",
  "& .MuiAccordionSummary-expandIcon svg":{
    fontSize:"14px",
  },
  "& .MuiAccordionDetails-root":{
    display: "flex",
    padding: "5px 0",
    background: "#232323",
 
  },
  "&:before":{
    display:"none",
  },
  "& ul":{
    width:"100%"
  },
  "& .MuiListItem-button":{
    padding:" 6px 12px 10px 40px",
  },
 "&  .MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "36px",
    background: "#3c3c3c",
    "& svg":{
      color:"#60E99B"
    },  
    "& p":{
      color:"#60E99B"
    },  
},
"& .MuiAccordionSummary-root":{
  minHeight: "40px",
  "& div":{
    alignItems: "center",
    margin:"6px 0",
  },
},
"& .MuiAccordionSummary-root:hover":{
  background: "rgb(85 85 85)",
    color: "#fff",
},
"& .MuiAccordionSummary-content.Mui-expanded": {
  margin: "6px 0",
},

},

playlisyShareLabel:{
  color: "#dcdcdc",
  display: "inline-block",
  fontSize: "12px",
  textTransform: "uppercase",
  verticalAlign: "top",
  marginBottom:"10px",
},
sharePlaylistUsersLabel:{
  color: "#dcdcdc",
    fontSize: "12px",
    textTransform: "uppercase",
    verticalAlign: "top",
    margin:"12px 0",
},
sharedUserList:{
  display:"flex",
    background: "#232323",
    borderRadius: "3px",
    marginBottom: "4px",
    padding: "8px",
    alignItems: "center",
    "& .MuiAvatar-circle":{
      marginRight: "12px",
    },
    "& .MuiAvatar-circle":{
      width: "30px",
      height: "30px",
      marginRight:"10px", 
      borderRadius: "50% !important",
    }
},
sharedUserListSinglePreview:{
  // flexGrow: "0",
  // flexBasis: "66%",
  // flexShrink: "0",
  // overflow: "hidden",
  width:"100%",
},
sharedUserListSingle:{
  // flexGrow: "0",
  //   flexBasis: "60%",
  //   flexShrink: "0",
  //   overflow:"hidden"
  width:"calc(100% - 140px)",
},
sharedUserListEmail:{
  color: "#CDCDCF",
  width: "auto",
  marginTop: "3px",
    overflow: "hidden",
    fontSize: "0.8em",
    textOverflow: "ellipsis",
    width: "90%",
    textAlign: "left",

},
sharedUserPermission:{
    color: "#CDCDCF",
    width: "120px",
    marginRight: "5px",
    overflow: "hidden",
    fontSize: "0.8em",
    textOverflow: "ellipsis",   
    textAlign: "right",
},
sharedUserListName:{
  width: "auto",
    overflow: "hidden",
    fontSize: "0.8em",
    textOverflow: "ellipsis",
},
notShared:{
  fontSize:"0.75em",
},
reportsSearchBox:{
  textAlign:"left",
  display:"flex",
  alignItems:"end",
},

msgSpan:{
  fontSize: "0.75em",
  padding: "4px 10px",
  lineHeight: "19px",
  color:"#ede5b0",
  width: "100%",
},
// Global Create Windows
globalGreenButton: {
  background: "#0C853E",
  boxShadow: "none",
  color: "#fff",
  border:"1px solid #0C853E",
  textTransform: "none",
  fontWeight: "400",
  margin: "0px 10px",
  padding:"5px 15px",
  fontSize:"0.75em",
  "& .MuiCircularProgress-colorPrimary":{
    width: "16px !important",
    height: "16px !important",
    marginLeft: "7px",
    color: "#fff",
  },
  "&:hover": {
      background: "#000",
      color: "#29D211",
      border:"1px solid #403f3f",
  },
},
globalGrayButton: {
background: "#545454",
boxShadow: "none",
color: "#fff",
textTransform: "none",
fontWeight: "400",
margin: "0px 10px",
fontSize:"12px",
"&:hover": {
    background: "#393939",
    color: "#fff",
},
},

globalCreateBody:{
  background:"#000",
  // height: "100vh",
},
globalInput: {
  width:"100%",
  padding: "0",
  "& input": {
    borderRadius: "5px !important",
    padding: "10px",
     fontSize:"0.8em",
    background:"#2d2d2d"
  },
  "& .MuiAutocomplete-inputRoot":{
    background:"#2d2d2d",
  },
  "& .MuiFormControl-fullWidth":{
    // background:"#2d2d2d",
  },
  "& > div": {
    borderRadius: "5px",
    width:"-webkit-fill-available !important",
    width:"100% !important",
  },
  "& div.MuiAutocomplete-endAdornment":{
    width:"auto !important",
  },
  "& div.MuiCircularProgress-indeterminate":{
    width:"20px !important",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "#46af22 !important",
  },
  "&  .MuiAutocomplete-inputRoot": {
    padding:" 2px 9px",
},
},
globalButtonsList:{
  textAlign:"right",
  padding: "0 15px",
},
globalBlock:{
  display:"flex",
  "& > div":{
    padding: "0 10px",
  },
  "& strong":{
    fontSize:"12px",
    fontWeight:"500"
  },
},
globalCreateAdditionalPadding:{
  paddingTop: "40px !important",
},
globalNameBlock:{
  display:"flex",
  "& > div":{
    padding: "5px  15px",
    width:"100%",
  },
  "& .MuiSelect-select":{
    padding: "10px 30px 10px 10px",
    background:"#2d2d2d",
  },
 
  "& .MuiOutlinedInput-root":{
    width:"100%",
  },
  "& input":{
    background:"#2d2d2d",
  }
},
addChipset:{
  width:"100%",
    "& .MuiOutlinedInput-root":{
    background:"#2d2d2d",
    paddingTop:"0",
    paddingLeft:"8px",
    },
    "& .MuiChip-root":{
    float: "left",
    margin: "0",
    fontSize: "12px",
    height: "28px",
    },
    "& .MuiChip-deletable:focus":{
      background:"#296d12",
    },
  },
globalHeader:{
  display: "flex",
  background: "#292929",
  alignItems: "center",
  zIndex: "8",
  position: "relative",
  padding: "13px 10px",
  "& .MuiAvatar-circle":{
    margin:"0 10px",
  },
  "& > span":{
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow:" ellipsis",
  }
},
globalModuleWrap:{
  marginTop:"30px",
  position:"relative", 
  marginBottom:"20px",
   
  // "& button":{
  //   position:"absolute",
  //   top:"26px",
  //   right:"-8px",
  //   width: "30px",
  //   border: "1px solid #999",
  //   height: "30px",
  //   minWidth: "inherit",
  //   background:" #000",
  //   borderRadius: "50%",
  //   "& svg":{
  //     color: "#fff",
  //   fontSize: "15px",
  //   },
  //   "& span":{
  //     lineHeight:"1"
  //   }
  // }

},
globalPlaylistCreateWrap:{
  padding:"10px 10px 10px 20px",
  position:"relative",
  maxWidth: "400px",
    "& img":{
      borderRadius: "4px",
    },
  "& button":{
    position:"absolute",
    top:"4px",
    right:"-8px",
    width: "30px",
    border: "1px solid #999",
    height: "30px",
    minWidth: "inherit",
    background:" #000",
    borderRadius: "50%",
    "& svg":{
      color: "#fff",
    fontSize: "15px",
    },
    "& span":{
      lineHeight:"1"
    }
  }

},
globalListContents:{
  display:" flex",
  borderBottom: "1px solid #383838",
  borderRadius: "0 !important",
  padding: "6px 0",
  margin:"0 10px",
  fontSize:"14px",
  alignItems: "center",
  "& .Mui-checked":{
    color: "#0C853E",
  },
  "& .MuiSwitch-track":{
    backgroundColor: "#0C853E !important",
  },

},
globalTextSpan:{
  width:"-webkit-fill-available",
  width:"100%",
},
globalConditionResults:{
  fontSize:"0.8em",
  marginBottom: "15px",
},
globalRuleConditionName:{
  color: "#FCE939",
  display: "inline-block",
  borderRadius: "3px",
  margin: "8px 0 5px 0",
  textDecoration: "underline",
  display: "block",
  clear: "left",
  fontSize: "12px",
 
},
globalTrashButton: {
  color: "#ff5f84",
  boxShadow: "none",
  color: "#fff",
  textTransform: "none",
  fontWeight: "400",
  margin: "0px 0px 0px 10px",
  padding: "8px !important",
  fontSize:"12px",
  minWidth: "inherit",
  cursor:"pointer",
  borderRadius: "5px",
  height: "33px !important",

  width: "33px!important",
  "&:hover": {
      background: "rgba(255, 255, 255, 0.08)",
      color: "#f1527e",
  },
  "& svg":{
    fontSize: "15px",
    color:"#ff5f84"
  },
},
globalConditionList:{
  background: "#444444",
    padding: "5px 10px",
    borderRadius: "5px !important",
    fontSize: "13px"
},
globalInputWrap:{
  "& > div":{
    display:"inline-block",
    "& input":{
      padding:"8px 8px 8px 10px",
      background: "#484848",
    }
  },
  "& .MuiSelect-select":{
    paddingRight:"0 !important",
  },
  "& label":{
    fontSize: "12px",
    top:" -10px !important",
  },
  "& .MuiInputLabel-shrink":{
    top: "2px !important",
  },
  "& .Mui-focused":{
    color:"#fff !important"
  }
},
tagWrapMainInput:{
  "& > div":{
    display:"inline-block",
    "& input":{
      padding:"8px 8px 8px 10px",
      background: "#484848",
    }
  },
},
globalCreateHeader:{
  display: "flex",
  background: "#292929",
  alignItems: "center",
  zIndex: "8",
  position: "relative",
  padding: "13px 10px",
  "& .MuiAvatar-circle":{
    margin:"0 10px",
  }
},
globalCreateName:{
width:"100%",
whiteSpace:"nowrap",
overflow: "hidden",
textOverflow: "ellipsis",
width: "100%",
},
globalCreateLabel:{
  display:"block",
  fontSize:"0.75em",
  color:"#DEDEDE",
  textTransform: "uppercase",
  margin: "15px 0 5px 0",
  lineHeight: "19px",
},
globalCreateInlineLabel:{
  fontSize:"12px",
  color:"#DEDEDE",
  textTransform: "uppercase",
  margin: "15px 0 5px 0",
},
globalCreateContentWrapper:{
  borderRadius: "5px", 
  padding: "20px",
},
globalSelectBox:{
  width:"100%",
  background: "#2d2d2d",
  "& > div": {
    borderRadius: "5px",
    width:"100%",
    padding: "10px",
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    borderColor:"#46af22 !important",
  }
},
globalCreateInput: {
  width:"100%",
  padding: "0",
  "& input": {
    borderRadius: "5px !important",
    padding: "12px",
     fontSize:"0.8em",

  },
  "& > div": {
    borderRadius: "5px",
    width:"-webkit-fill-available !important",
    width:"100% !important",
      overflowY: "auto",
  height:" calc(100% - 73px)",
  position: "absolute", 

  },
  "& div.MuiAutocomplete-endAdornment":{
    width:"auto !important",
  },
  "& div.MuiCircularProgress-indeterminate":{
    width:"20px !important",
    height:"20px !important",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "#46af22 !important",
  },
  "&  .MuiAutocomplete-inputRoot": {
    padding:" 2px 9px",
},
},
globalCreateAddButton: {
  background: "#0C853E",
  boxShadow: "none",
  color: "#fff",
  border:"1px solid #0C853E",
  textTransform: "none",
  fontWeight: "400",
  margin: "15px 0px 5px 0px",
  padding:"5px 15px",
  fontSize:"0.8em",
  "&:hover": {
      background: "#000",
      color: "#29D211",
      border:"1px solid #403f3f",
  },
},

globalSelectEmptyError:{
  background:"#9c214e",
  marginBottom: "5px",
},

dialogYesButton:{
  background:"#118543",
  "&:hover":{
    background:"#000",
    color:"#118543",
  }
},
dialogNoButton:{
  background:"#505050",
  "&:hover":{
    background:"#000",
    color:"#fff",
  }
},

globalCreateSaveButton: {
  background: "#0C853E",
  boxShadow: "none",
  color: "#fff",
  border:"1px solid #0C853E",
  textTransform: "none",
  fontWeight: "400",
  margin: "0px 0 0px 10px",
  padding:"5px 15px",
  fontSize:"0.75em",
  "&:hover": {
      background: "#000",
      color: "#29D211",
      border:"1px solid #403f3f",
  },
  "& .MuiCircularProgress-colorPrimary":{
    width: "13px !important",
    height: "13px !important",
    color: "#fff !important",
    marginLeft: "5px !important",
  },
},
globalCreateCloseButton: {
  background: "#545454",
  boxShadow: "none",
  color: "#fff",
  textTransform: "none",
  fontWeight: "400",
  margin: "0px 10px",
  fontSize:"0.8em",
  padding:"5px 15px",
  "&:hover": {
      background: "#393939",
      color: "#fff",
  },
},
globalCreateDate:{
  display:"flex",
  "& > div":{
    width:"95%",
  },
  "& > div div":{
    width:"100%",
  }
},
globalToDate:{
    margin:"0 10px  0 0",
    width:"90% !important",
    
},
globalCreateText:{
  width:"100%",
  padding: "0",
  "& textarea": {
    borderRadius: "5px !important",
    padding: "0px",
     fontSize:"0.8em",
    background:"none"
  },
  "& > div": {
    borderRadius: "5px",
    width:"-webkit-fill-available !important",
    width:"100% !important",
  },
  "& div.MuiAutocomplete-endAdornment":{
    width:"auto !important",
  },
  "& div.MuiCircularProgress-indeterminate":{
    width:"20px !important",
  },
  "& .MuiOutlinedInput-multiline":{
    padding:"10px !important",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "#46af22 !important",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
    borderColor: "#46af22 !important",
    border:"1px solid",
  },
  "&  .MuiAutocomplete-inputRoot": {
    padding:" 2px 9px",
},
},
textRight:{
  textAlign:"right",
},
floatRight:{
  float:"right",
},
contextIcons:{
  fontSize:"16px",
  marginRight:"10px"
},
playlistAvatar:{
  width: "45px",
    borderRadius: "4px",
    border: "1px solid #5d5d5d",
},
newIconFonts:{
  minWidth: "32px",
  "& svg":{
  fontSize:"19px",
}
},
createNewPlaylist:{
   background:"#04823a !important",  
    width: "12.5rem !important",
    maxWidth: "inherit !important",
    textTransform: "none",
    fontWeight: "300",
    fontSize: "12px",
    height: "inherit !important",
    padding: "7px",    
    justifyContent: "flex-start",
    textAlign: "left",
    borderRadius:"0",
    margin:"5px 0px !important",
    "&:hover":{
      background:"#0c9244 !important",
    },
  "& svg":{
    marginRight: "5px",
    fontSize:"22px",
    marginLeft: "8px",   
  }
},
createNewClearance:{
  background:"#005780   !important",  
   width: "12.5rem !important",
   maxWidth: "inherit !important",
   textTransform: "none",
   fontWeight: "300",
   fontSize: "12px",
   height: "inherit !important",
   padding: "7px",    
   justifyContent: "flex-start",
   textAlign: "left",
   borderRadius:"0",
   margin:"5px 0 10px 0px !important",   
 "& svg":{
   marginRight: "5px",
   fontSize:"22px",
   marginLeft: "8px",   
 },
 
},
createNewPlayout:{
  background:"#008fa4   !important",  
   width: "12.5rem !important",
   maxWidth: "inherit !important",
   textTransform: "none",
   fontWeight: "300",
   fontSize: "12px",
   height: "inherit !important",
   padding: "7px !important",    
   justifyContent: "flex-start",
   textAlign: "left",
   borderRadius:"3px",
   margin:"16px 18px !important",
   "&:hover":{
     background:"#008397  !important",
   },
 "& svg":{
   marginRight: "5px",
   fontSize:"22px",
   marginLeft: "8px",   
 },
 "&.Mui-focusVisible":{
  background:"#04798a  !important", 
}
},
contentAlert:{
  background:"#8f388a", 
  maxWidth: "inherit !important",
  textTransform: "none",
  fontWeight: "300",
  fontSize: "12px",
  height: "inherit !important",
  padding: "7px 7px 7px 12px !important",    
  justifyContent: "flex-start",
  textAlign: "left",
  borderRadius:"3px",
  width: "200px",
  margin:"16px 0 10px 18px !important",
  "&:hover":{
    background:"#73286f !important",
  },
"& svg":{
  marginRight: "6px",
  fontSize:"17px",
  marginLeft: "0",   
},
"&.Mui-focusVisible":{
  background:"#0b987e", 
}
},
contentMessage:{
    background:"#047c66 ", 
    maxWidth: "inherit !important",
    textTransform: "none",
    fontWeight: "300",
    fontSize: "12px",
    height: "inherit !important",
    padding: "7px 7px 7px 12px !important",    
    justifyContent: "flex-start",
    textAlign: "left",
    borderRadius:"3px",
    width: "200px",
    margin:"16px 0 10px 18px !important",
    "&:hover":{
      background:"#049076 !important",
    },
  "& svg":{
    marginRight: "6px",
    fontSize:"17px",
    marginLeft: "0",   
  },
  "&.Mui-focusVisible":{
    background:"#0b987e", 
  }
},

exportMenu:{
    textTransform: "none",
     margin:"5px 0 5px 0px !important",
},
runLiveClearance:{
  background:"#0d91a7  !important",
  margin:"10px 18px 5px 18px !important",
  "&.Mui-focusVisible":{
    background:"#056575  !important", 
  }
},
addtoClearanceButton:{
  background:"#005780 ",
  // width: "12.5rem !important",
  maxWidth: "inherit !important",
  textTransform: "none",
  fontWeight: "300",
  fontSize: "12px",
  height: "inherit !important",
  padding: "7px 7px 7px 12px ",    
  justifyContent: "flex-start",
  textAlign: "left",
  borderRadius:"3px",
   width: "200px",
  margin:"15px 16px 5px 16px ",
  "&:hover":{
    background:"#007CB4 !important",
  },
"& svg":{
  marginRight: "6px",
  fontSize:"17px",
  marginLeft: "0",   
},
"&.Mui-focusVisible":{
  background:"#1286bd", 
},

},
SearchButton:{
  fontSize: "0.75em",
    padding: "5px 10px 5px 5px",
    fontWeight: "400",
    minWidth: "90px ",
    textTransform: "none",
    background: "#005241",
    border:"1px solid #13924f",
    "& svg":{
      marginRight: "5px",
    },
   
},
customError:{
  margin: "8px 0 10px 0",
  fontSize: "12px",   
  color: "#FFD1D1  !important",
  "&.MuiInput-root":{
    border: "1px solid #f44336 !important",
    background: "#9c214e !important",
  },

},
createNewBox:{
  display:"flex",
  position:"relative",
  justifyContent: "flex-start",
  alignItems: "center",
  "& svg":{
    width: "31px",
    margin: "0px",
    fontSize:"17px"
  },
  "& button":{
    margin:"0 0px 0 5px",
    borderRadius:"3px",
    height: "26px",
    width: "26px",
  },
  "& .MuiOutlinedInput-input":{
    paddingRight:"70px !important",
    background: "#504e4e",
    padding:"10px 20px 10px 15px",
  },

  "&.MuiListItem-button:hover":{
    background:"transparent",
    "& > .MuiButton-root":{
      background:"#007CB4  !important",
    },
 
  },
  "&.MuiListItem-root.Mui-focusVisible":{
    backgroundColor: "transparent !important",
    "& > .MuiButton-root":{
     background:"#007CB4  !important",
    },
  
  }


     
},
createNewWraper:{
  position:"relative",
  "& > div > div":{
    paddingLeft:"17px !important"
  },

},
disableHover:{
  background:"transparent",
  "&.MuiListItem-button:hover":{
    background:"transparent",
    "& > .MuiButton-root":{
      background:"#025f29  !important",
    },
    "& .MuiOutlinedInput-adornedEnd":{
      background:" #464646",
    },
    "& .MuiSelect-select":{
      background:" #464646",
    }
  },
  "&.MuiListItem-root.Mui-focusVisible":{
    backgroundColor: "transparent !important",
    "& > .MuiButton-root":{
     background:"#025f29  !important",
    },
    "& .MuiOutlinedInput-adornedEnd":{
      background:" #464646",
    },
    "& .MuiSelect-select":{
      background:" #464646",
    }
  }
},
createButtonsSet:{
    top:"14px",
    marginRight:"10px",
    position: "absolute",
    right: "0px",
},
actionMenuIcon:{
  marginRight:"10px",
  fontSize: "18px",
},
collapsibleButtonLeft: {
  position: "absolute",
  left: "59px",
  top: "0px",
  zIndex: "9",
  background: "#00ADAB",
  width: "10px !important",
  minWidth: "inherit",
  padding: "30px 2px 30px 2px",
   fontSize:"1em",  
},
expandableButtonLeft:{
  position: "absolute",
  left: "177px",
  top: "0",
  zIndex: "9",
  background: "#00ADAB",
  width: "10px !important",
  minWidth: "inherit",
  padding: "30px 2px 30px 2px",
   fontSize:"1em",
},
 
disableDiscreteIcons:{
  color:"#999",
},
activeleDiscreteIcons:{
  color:"#fff",
},
addedTagClose:{

    borderRadius: "50px",
    border: "1px solid #4a4949",
    background: "#424242",
    color: "#fff",
    verticalAlign: "middle",
    marginLeft: "5px",
"&:hover":{
  background: "#383535",
  cursor: "pointer",
},
"& span":{
  verticalAlign: "middle",
}  
},
HeadAddMargin:{
  marginLeft:"10px",
},
commonButton:{
  margin:"0 5px",
  padding:"4px 12px 3px 12px  !important",
 
},
actionIcons:{
  fontSize:"16px",
  marginRight:"8px",
},
moduleMainTitle:{
  marginTop: "10px",
    display: "block",
    color: "#e2b125",
    fontSize: "14px",
    textTransform: "uppercase",
},
composeTextAreaContReq:{
  "& textarea":{
    width: "100%",
    padding: "10px",
    boxSizing: "border-box",
    marginBottom:" 10px !important",
    borderRadius: "4px",
    background: "#4a4949",
    border:" 1px solid #747373",
    color:" #fff",
    fontFamily: "Arial",
    resize: "none",
    maxHeight: "250px",
    minHeight:"80px",
    outline:"none",
    "&:focus":{
      borderColor:"#46af22"
    },
    "&:hover":{
      borderColor:"#46af22",
    },
  }
},
overrideTextArea:{
  "& textarea":{
    width: "100%",
    padding: "10px",
    boxSizing: "border-box",
    marginBottom:" 10px !important",
    borderRadius: "4px",
    background: "#2d2d2d",
    border:" 1px solid #747373",
    color:" #fff",
    fontFamily: "Arial",
    resize: "none",
    maxHeight: "250px",
    minHeight:"80px",
    outline:"none",
    "&:focus":{
      borderColor:"#46af22"
    },
    "&:hover":{
      borderColor:"#46af22",
    },
  }
},
emptyData:{
  "& textarea":{
    background: "#9C214E!important",
    border:"1px solid #f44336!important",
    marginBottom: "0px !important",
  },
  "& span":{
    color: "#FFD1D1 !important",
    fontSize: "0.75em",
  }
},

contentReqCreatDisabled:{
  background: "#676262 !important"
},

horizantalBorder:{
  border: "none",
  background: "#565656",
  height: "1px",
  margin:"0",
},
reqSymbol:{
  color:"#ffa2a2 !important",
  marginLeft:" 3px !important",
  fontSize: "1.5em !important",
  marginRight: "4px !important",
  verticalAlign: "middle !important",
},
contentMessageAttachments:{
  textAlign: "left",
  padding: "10px",
  "& span > span":{
    lineHeight: "1.2",
    marginTop:"5px",
  }
},
dialogSubmitButton:{
  alignItems: "center !important",
  justifyContent: "flex-end !important",
  marginBottom: "10px !important",
  paddingRight: "20px !important",
  paddingTop: "15px !important",
},  
dialogMinWidth:{
  minWidth:"600px",
  overflowX: "hidden",
  "& .MuiTypography-h6":{
      fontSize:"1.1em",
      lineHeight: "31px"
  },
  "& .MuiDialogTitle-root":{
      padding:"8px 18px",
  },
  "& img":{
    margin: "10px auto",
    display: "block",
    borderRadius: "3px",
    border: "2px solid #7b7b7b",
    maxWidth:"100%",
  },
  "& video":{
    margin: "20px auto",
    display: "block",
    borderRadius: "3px",
    border: "2px solid #7b7b7b",
    maxWidth:"100%",
  },
  "& iframe":{
    margin: "20px auto",
    display: "block",
    borderRadius: "3px",
    border: "2px solid #7b7b7b",
    maxWidth:"100%",
  },
  "& span":{
    wordBreak: "break-word",
  }
},
appbarWrapButtonOveride:{
  "& button":{
    // minHeight:"34px",
    "& svg":{
      height: "21px",
      marginRight: "5px"
    }
  }
},

modalContentWidth:{
  minWidth:"600px",
  padding:"0 !important",
  "& > div":{
    padding:"0 20px",
  }
},
modalContentHeader:{
  display:"flex",
  alignItems: "center",
},
modalContentTitle:{
    width: "100%",
    textAlign: "left",
},
contentHelpDropdownContainer:{
  margin:"20px 0 0 0",
},
contentHelpDropdown:{
  "& .MuiSelect-select":{
    padding:"10px 30px 10px 10px",
    textAlign: "left",  
  },
  "& .MuiSelect-select:hover":{
    outline:"none",
    borderColor:"#46af22",
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    outline:"none",
    borderColor:"#46af22",
  },

},
globalContentCreateAddBottomPadding:{
  paddingBottom:"10px",
},
globalContentCreateAddPadding:{
  paddingTop:"20px",
},
smallIntro:{
  fontSize: "0.75em !important",
  color: "#f4c200",
  "& span":{
    color: "#fff",
  }
},
dialogFooter:{
  justifyContent:" flex-start !important",
  padding: "0 20px",
  "& button":{
    marginRight:"15px !important",
    padding:"8px 15px",
  }
},
priorityText:{
  color:"#f4c200",
  fontSize:"0.8em",
  margin:" 6px 0 15px 0",
  display: "block",
},
warningText:{
  color:"#f4c200",
  fontSize:"0.8em",
  margin:" 0 0 10px 0",
  display: "block",
},
globalContentCreate:{
  textAlign:"left",
  fontSize: "0.9em",
  "& .MuiOutlinedInput-root":{
    borderRadius:"4px",
  },
  "&  label":{
    margin:"0 0 8px 0",
  }
},
closeButton: {
  background: "transparent",
  boxShadow: "none",
  color: "#F8F8F8",
  textTransform: "none",
  float: "right",
  fontWeight: "400",
  minWidth: "0px",
  padding: "7px 10px !important",
  width:"32px",
  marginLeft: "10px",
  borderRadius: "50%",
  border:"1px solid #525252",
  "& svg":{
    fontSize: "16px",
  },
  "&:hover": {
    background: "#000",
    boxShadow: "none",
  },
},

tagSearchCondition:{
    position:"relative",
    display:"inline-block",
  "& .MuiInput-root":{
    border:"none",
    margin:"0",
      "& > div":{
        border: "none",
        background: "#5d5d5d",
        borderRadius: "3px",
        padding: "5px 26px 5px 8px",
        fontSize: "10px",
        minHeight: "inherit",    
      },
      "& svg":{
        marginTop: "5px",
        marginRight: "5px",
      }
  },
  },
  globalClearButton:{
    width: "auto !important",
   "& svg":{
     fontSize:"17px",
   }  
  },

// Help Styles
helpbuttonCustomSize: {
  "& svg": {
    fontSize: "16px !important",
  },
},
helpPanelHeader:{
  background:"#212121",
  "& h2":{
    fontSize:"1em",
    color:"#edc548",
    "& svg": {
      fontSize: "26px",
      marginRight:"6px",
      color:"#edc548"
    },
  },
 
},
helpCommonTopButton: {
  padding: "9px",
  height: "35px",
  width: "39px",
  minWidth: "inherit",
  marginTop: "7px",
  margin: "6px 5px 8px 10px!important",
},
textAlignLeft:{
  textAlign:"left",
},
helpGlobalWrapperRight:{
  position: "relative",
  height: "100vh",
  overflow:"hidden",
},
helpSingleItems:{
  borderBottom:"1px solid #5a5858",
  padding: "8px",
 borderRadius: "3px",
 wordBreak: "normal",
 whiteSpace: "normal",
 "& svg":{
  marginRight: "10px",
  fontSize: "18px",
  minWidth: "30px"
 }
},
closeButton: {
  background: "transparent",
  boxShadow: "none",
  color: "#F8F8F8",
  textTransform: "none",
  float: "right",
  fontWeight: "400",
  minWidth: "0px",
  padding: "7px 10px !important",
  width:"32px",
  marginLeft: "10px",
  border:"1px solid #525252",
  "& svg":{
    fontSize: "16px",
  },
  "&:hover": {
    background: "#000",
    boxShadow: "none",
  },
},
helpCommonButton:{
width: "35px",
height: "31px",
minWidth: "inherit",
// marginTop: "12px"
},

helpSearchArea: {
display: "flex",
padding: "15px",
background: "#313131",
alignItems: "start",
marginBottom: "0px",
"& form":{
    display: "flex",
    width: "100%",
    "& .MuiTextField-root":{
        width:"-webkit-fill-available",
    },
    "& .MuiInput-root":{
        margin:"0"
    }
},
"& .MuiInput-underline:before": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "2px solid #46af22",
  },
"& .MuiButtonBase-root":{
  marginTop:"0px !important",
},
"& .MuiListItem-button:hover": {
  background: "#424242",
},
"& .MuiListItem-button .active": {
  background: "#424242",
},
"& .MuiChip-outlined": {
  border: " 1px solid #00a5ef",
  fontSize: "12px",
  backgroundColor: "transparent",
  height: "30px",
  maxWidth:"130px",
  background:"#0079af !important",
  "& .MuiChip-label": {
    color: "#fff",
  },
},
"& .dropdown-menu a.disabled": {
  background: "#383838",
  padding: "10px",
  fontSize: "12px",
  display: "block",
  color: "#ffcb0e",
  border: " 1px solid #6d6a6a",
  borderTop: "none",
  borderRadius: "0px 0px 3px 3px",
},
"& .MuiChip-root": {
  fontSize: "12px",
  marginRight: "5px",
  marginBottom: "5px",
  marginLeft: "5px",
},
"&  input": {
  padding: "9px 12px",
  background: "#5a5a5a",
  borderRadius: "3px",
  color: "#fff",
  border: "1px solid rgba(255, 255, 255, 0.23)",
  fontSize: "12px",
  width: "100%",
},
"& input:focus": {
  borderColor: "#46af22 !important",
  outline: "none",
},
"& input:hover": {
  borderColor: "#46af22 !important",
},
},
helpSearchButton: {
minWidth: "inherit",
padding: " 8px 10px 9px 10px",
width: "36px",
"&:hover": {
  background: "#04923F",
  color: "#fff",
},
"& svg": {
  fontSize: "16px",
},
"& .MuiCircularProgress-colorPrimary": {
  color: "#fff",
  width: "10px !important",
  margin: "0 !important",
  height: "10px !important",
},
},
helpmuListWrap:{
  top: "0",
  width: "100%",
  height: "calc(100% - 60px)",
  padding: "20px",    
  overflow: "auto",
  position: "absolute",
  background: "#424242",
  overflowX: "hidden",
  "& .MuiAccordion-root.Mui-expanded":{
    background: "#232323",
  },
  "& .MuiAccordionSummary-content.Mui-expanded":{
    margin:"5px 0",
  },
  "& p":{
     fontSize:"0.8em",
  },
  "& .MuiAccordionSummary-root":{
    minHeight:"40px",
    background: "#323232",
    "&:hover":{
      background: "#484848",
    },
    "&.Mui-focused":{
      background: "#484848",
    }
  }
},
helpPaperwrap:{
  position: "relative",
  height: "calc(100% - 123px)",
  width: "calc(100% - 40px)",
  background: "none",
  boxShadow: "none",
},
     
helpNoResults:{
  fontSize: "12px",
  textAlign: "center",
  display: "block",
},
helpDetails:{
  "&":{
    display:"block",
  },
  "& .MuiAccordionDetails-root":{
    display:"block",
  }
},
 
playStationSearchBlock: {
  display: "flex",
  alignItems: "start",
  textAlign: "right",
  "& .MuiButtonBase-root":{
    marginTop:"0px !important",
    width: "38px",
    minWidth: "inherit",
    textAlign: "center",
    height: "34px",
    padding: "10px 6px",
    background:"#0c853e !important",
    "& svg":{
      margin:"0",
    }
  },
  "& .MuiListItem-button:hover": {
    background: "#0c853e !important",
  },
  "& .MuiListItem-button .active": {
    background: "#0c853e !important",
  },
  "& .MuiTextField-root":{
      width:"100%",
  },
  "&  input": {
    padding: "9px 12px",
    background: "#357c9e",
    borderRadius: "3px",
    color: "#fff",
    border: "1px solid #357c9e",
    fontSize: "12px",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "#46af22 !important",
  },
  "& input:focus": {
    borderColor: "#46af22 !important",
    outline: "none",
  },
  "& input:hover": {
    borderColor: "#46af22 !important",
  },
},
emptyTracks:{
  padding:"10px",
  display:"block",
  textAlign:"center",
},
sentpopupHeader:{
  display:"flex",
  alignItems:"center",
  marginTop: "3px",
  fontSize:"18px",
},
sentTracksRemove:{
  minWidth:"inherit",

},
Muierror:{
  margin: "0",
  color: "#FFD1D1",
  marginTop: "10px",
  fontSize:"12px",
  display:"block",
},
dateTimeFilter:{
  display:"flex",
  "& .onlyDateInput .MuiInputAdornment-root":{
    marginRight: "0px",
  },
  "& .searchHighlighter .MuiInputAdornment-root":{
    marginRight: "25px",
  },
  "& .MuiInput-formControl":{
    margin:"0"
  },
  "& label": {
    fontSize: "12px",
    top: " -12px !important",
    left: "35px",
    zIndex:"1",
  },
  "& .MuiInputLabel-shrink":{
    left: "20px",
    top:"-5px !important"
  }
},
dateFilter:{
  display:"flex",
},
bpmFilter:{
  "& .MuiTextField-root":{
    background: "#4c4c4c",
    paddingRight: "27px",
  }
},

tableFilterDate:{
  marginTop: "0",
    marginBottom: "0",
    "& input":{
      padding:"10px 10px 8px 33px !important",
    },
    "& .MuiInput-underline:after":{
      display:"none",
    },
    "& .MuiInput-underline:before":{
      display:"none",
    },
    "& .MuiInput-underline":{
      border: "1px solid rgb(76 76 76) !important",
      background:"transparent !important",
      "& button":{
        borderRadius:"5px",
      },
      "& .MuiIconButton-root":{
        width:"30px",
        height:"30px",
      },
      "&:hover":{
        borderColor: "#46af22",
      },
      "&:focus":{
        borderColor: "#46af22",
      }
    },
    "& .Mui-focused":{
      borderColor: "#46af22",
    },
    "& .MuiInputAdornment-root":{
      width:"auto",
      marginRight:"3px",
      "& svg":{
        width:"18px",
      }
    },
    "& .Mui-disabled .MuiInputAdornment-root":{
      marginRight: "28px",
    },
},
dateTimeToFilter:{
  "& input":{
    padding:"10px 10px 8px 10px !important",
  },
  "& .MuiInputLabel-root":{
    left: "16px",
  }
},
FilterDate:{
  "&:hover .MuiInput-underline":{
    borderColor: "#46af22 !important",
  },
  "& input":{
    padding:"7.5px 29px  7.5px 38px !important",
  },
  "&.removePadding input":{   
      padding:"7.5px 10px 7.5px 10px !important",
  },
  "&.removePadding ":{
    "& .MuiOutlinedInput-notchedOutline":{
      borderRadius:"0 5px 5px 0",
    }
  }
},
leftBorder:{
  "& .MuiOutlinedInput-notchedOutline":{
    borderRadius:"5px 0 0 5px",
  }
},
SearchButtonOverride:{
  fontSize:"0.9em",
},
 
ChartIndicator:{
  position: "absolute",
  right: "10px",
  top: "10px",
  background:"rgba(0,0,0,0.6)",
  borderRadius:"50px",
  width:"26px",
  height:"26px",
  padding:"8px",
  textAlign:"center"
},
savedNotification:{
  background: "#262626",
    borderRadius: "3px",
    fontSize: "0.75em",
    padding: "10px",
    textAlign: "left",
    border:"1px solid #5a5a5a",
    width:"auto !important",
    "& svg":{
      color:"green",
      fontSize:"24px",
      marginRight:"10px",
      verticalAlign:"middle",
    }
},
modalMsgContentInner:{
  fontSize:"1.4em"
},
userClBlocks:{
  width:"280px",
  fontSize:"1em",
  "& input":{
    padding:"7.5px 10px 7.5px 38px !important",
    fontSize:"0.75em",
  },
  "& label":{
    fontSize:"1em", 
    top: "-4px",
  }
},
Alertprogress:{
  color:"#fff",
  marginLeft:"5px",
},
profileSaved:{
  fontSize:"0.7em",
  marginTop:"5px",
  display:"block",
  textAlign:"center",
  color:"#f4c200",
},
profileLoader:{
  width:"30px !important",
  height:"30px !important",
  color:"green",
  margin:"5px auto",
  top: "52px",
  left: "0",
  right: "0",
  position:"absolute",
},
clearButton:{
  position:"absolute",
  top:"27px",
  right:"4px",
  width: "30px",
  height: "30px",
  minWidth: "inherit",
  background: "#3f668d",
},
hideText:{
  opacity:" 0",
  position: "absolute",
  left: "-1000px",
},
calendarFieldOverride:{
  position:"relative",
},
datevalidInput:{  
  "& .MuiInput-underline":{
    borderColor:"rgba(255, 255, 255, 0.23)",
  },
  "& input":{
    padding:"10px 30px 10px 10px !important",

  }

},
verifyingTracks:{
  position: "absolute",
    width: "100%",
    textAlign: "center",
    height: "316px",
    background: "rgba(0,0,0,0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1",
    fontSize: "13px",
    fontWeight:"bold",
}
};
