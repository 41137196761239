import { textLengths } from "../../../redux/Types";

export const validateCreateAlbum = (data) => {
    let errors = {};
    if (data) {
        if (!data.album_title || !data.album_title.length) {
            errors.album_title = "Title should not be empty.";
        }
        if (data.album_title?.length && data.album_title?.length > textLengths.TEXT) {
            errors.album_title = "Title should be less than 256 characters.";
        }

        if (data.album_artist?.length && data.album_artist?.length > textLengths.TEXT) {
            errors.album_artist = "Artist should be less than 256 characters.";
        }
        if (data.catalogue_number?.length && data.catalogue_number?.length > 32) {
            errors.catalogue_number = "Catalog # should be less than 33 characters.";
        }
        if (data.cLine?.length && data.cLine?.length > textLengths.TEXT) {
            errors.cLine = "C-Line should be less than 256 characters.";
        }
        if (data.bbc_album_id?.length && data.bbc_album_id?.length > 32) {
            errors.bbc_album_id = "BBC Album barcode should be less than 33 characters.";
        }

        if (data.org_album_admin_notes?.length && data.org_album_admin_notes?.length > textLengths.LONGTEXT) {
            errors.org_album_admin_notes = "Admin Notes should be less then 1000 characters.";
        }
        if (data.upc?.length && data.upc?.length > 32) {
            errors.upc = "UPC should be less than 33 characters.";
        }
        if (data.album_notes?.length && data.album_notes?.length > textLengths.LONGTEXT) {
            errors.album_notes = "Album notes should be less then 1000 characters.";
        }

        if (data.album_discs) {
            if(data.album_discs > 2147483647)
            errors.album_discs = "Album discs should be less than 2147483647";
        }    
        
        if (data.prod_year) {
            if(data.prod_year > 3000 || data.prod_year < 1000)
            errors.prod_year = "Prod. year should be between 1000 and 3000";
        }


        if (data.org_album_adminTags && data.org_album_adminTags.length > 0) {
            for (const tag of data.org_album_adminTags) {
                if (tag && tag.length > 32) {
                    errors.org_album_adminTags = "Tags should be less than 33 characters.";
                }
            }  
        }

    }
    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
};

