import { textLengths } from "../../redux/Types";
import { validateEmail } from "./UserActionsValidation"

export const ValidateAddUser = (userData) => {
        let errors = {}
    if (userData) {
        let existingUser = userData.id ? true : false;
        if (validateEmail(userData.email)) {
            errors.email = validateEmail(userData.email);
        }

        if (!existingUser) {
            if (validateEmail(userData.confirmEmail)) {
                errors.confirmEmail = validateEmail(userData.confirmEmail);
            } else if (!validateEmail(userData.email) && !validateEmail(userData.confirmEmail) && (userData.email !== userData.confirmEmail)) {
                errors.confirmEmail = "Confirm email does not match";
            }
        }


        if (!userData.firstName) {
            errors.firstName = "Should not be empty";
        } else if (userData.firstName && userData.firstName.length > textLengths.TEXT) {
            errors.firstName = "Sorry, the name you entered is too long. Please try a shorter name";
        }

        if (userData.lastName && userData.lastName.length > textLengths.TEXT) {
            errors.lastName = "Sorry, the name you entered is too long. Please try a shorter name";
        }

        if (userData.bbcCompany && userData.bbcCompany.length > textLengths.TEXT) {
            errors.bbcCompany = "Sorry, the Company name you entered is too long. Please try a shorter company name";
        }
        if (userData.bbcDepartment && userData.bbcDepartment.length > textLengths.TEXT) {
            errors.bbcDepartment = "Sorry, the BBC Department name you entered is too long. Please try a shorter department name";
        }
        if (userData.bbcCostCode && userData.bbcCostCode.length > textLengths.TEXT) {
            errors.bbcCostCode = "Sorry, the Cost Code you entered is too long. Please try a shorter Cost Code";
        }
        if (userData.bbcDivision && userData.bbcDivision.length > textLengths.TEXT) {
            errors.bbcDivision = "Sorry, the BBC division name you entered is too long. Please try a shorter division name";
        }

        if (!userData.roleid) {
            errors.roleid = "Should not be empty";
        }

    }


    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }


}