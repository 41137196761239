import { textLengths } from "../../redux/Types";
import { reportTypes, reportAllowedPeriod } from "../../modules/sm-reports/Types";

export const ValidateReport = (itemData) => {
    let errors = {}

    errors = Object.assign(errors, validateNameAndDescription(itemData))

    errors = Object.assign(errors,  validateReportFunc[itemData.type](itemData))
    
    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}


let validateReportFunc = {}

validateReportFunc[reportTypes.UserStatus] = function (itemData) {
    return {};
}

validateReportFunc[reportTypes.UserActivityCount] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.UserActivityLog] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    errors = Object.assign(errors, validateUser(itemData));

    return errors;
}

validateReportFunc[reportTypes.ContentActivityByIP] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.Top1000TrackRanking] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.Clearance] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    errors = Object.assign(errors, validateClearanceFormId(itemData));

    return errors;
}

validateReportFunc[reportTypes.ContentSummaryByWorkspace] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.ContentSummaryByLibrary] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    errors = Object.assign(errors, validateWorkspace(itemData));

    return errors;
}

validateReportFunc[reportTypes.TakendownTracks] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.ContentRequestSummary] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.ContentRequestDetailed] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.NorthAmericanExemption] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.SpeechRadioExemption] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

validateReportFunc[reportTypes.AbuseFlag] = function (itemData) {
    let errors = {}

    errors = Object.assign(errors, validateDateRange(itemData));

    return errors;
}

// ------------------------------------------------------------------------
const validateNameAndDescription = (itemData) => {
    let errors = {}

    if (!itemData.name) {
        errors.name = "Should not be empty";
    } else if (itemData.name && itemData.name.length > textLengths.TEXT) {
        errors.name = "Sorry, the name you entered is too long. Please try a shorter name";
    }

    if (itemData.description && itemData.description.length > textLengths.LONGTEXT) {
        errors.description = `Sorry, the description you entered is too long (${itemData.description.length} chars). 
                    Please try a shorter one (max 1000 chars)`;
    }

    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}

const validateDateRange = (itemData) => {
    let errors = {}

    let maxRangeValue = 0;
    let maxRangeUnit = 'M';
    const p = reportAllowedPeriod.find(p => p.reportType === itemData.type);
    if (p) {
        maxRangeValue = p.number;
        maxRangeUnit = p.unit;
    }


    if (!itemData.datefrom)
        errors.datefrom = "Should not be empty";

    if (!itemData.dateto)
        errors.dateto = "Should not be empty";

    const dateFrom = new Date(itemData.datefrom);
    const dateTo = new Date(itemData.dateto);
    const today = new Date();
    if (dateFrom > today)
        errors.datefrom = "'Date from' must be before today's date";

    if (dateFrom > dateTo)
        errors.dateto = "Please enter 'date to' greater than 'date from'";

    let diff = null;
    if (maxRangeUnit == 'M') {
        // Validate range in  months
        diff = monthDiff(dateFrom, dateTo);
    } else {
        // Validate range in  days
        diff = dayDiff(dateFrom, dateTo);
    }
    
    if (diff > maxRangeValue)
        errors.dateto = `Please do not exceed a period of ${maxRangeValue} ${(maxRangeUnit == 'M') ? "month(s)" : "days"}`;


    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}


function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

function dayDiff(dateFrom, dateTo) {
    const diffInMs = new Date(dateTo) - new Date(dateFrom)
    return Math.trunc(diffInMs / (1000 * 60 * 60 * 24));
}

const validateUser = (itemData) => {
    let errors = {}

    if (!itemData.params?.user?.id) {
        errors.user = "Please select a user";
    }

    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}

const validateWorkspace = (itemData) => {
    let errors = {}

    if (!itemData.params?.workspace?.wsId) {
        errors.ws = "Please select a workspace";
    }

    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}

const validateClearanceFormId = (itemData) => {
    let errors = {}

    if (itemData.params?.cfid && !isValidGUID(itemData.params?.cfid)) {
        errors.cfid = "Please enter a valid Clearance Form ID";
    }
    
    if (Object.entries(errors).length === 0) {
        return null;
    } else {
        return errors;
    }
}

function isNumeric(value) {
    return /^[1-9][0-9]*$/.test(value);
}

function isValidGUID(value) {
    if (value && value.length > 0) {
        if (!(/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(value))
            return false;
    }
    return true;
}

