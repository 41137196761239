import React from "react";
import { booleanType, OriginTypes, PageTableType, SubOriginTypes } from "../../../redux/Types";
import HighlightedText from "../../../util/HighlightedText";
import {
  ColumnStickyTypes,
  ColumnTypes,
  JustificationTypes,
} from "../../../util/table-view/TableTypes";
import trackFields from "../../../_module/definition/trackFields";
import i18next from "i18next";

export default {
  selector: {
    width: 20,
    alignHeader: JustificationTypes.center,
    alignCell: JustificationTypes.center,
    type: ColumnTypes.selector,
    sticky: ColumnStickyTypes.left,
  },
  prodArtworkUrl: {
    width: 25,
    alignHeader: JustificationTypes.center,
    alignCell: JustificationTypes.center,
    type: ColumnTypes.thumb,
    tablePageType: PageTableType.SEARCH_TRACKS,
  },
  id: {
    width: 220,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    type: ColumnTypes.identity,
  },
  dhTrackId: {
    width: 120,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    type: ColumnTypes.identity,
  },
  dhExplicit: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    type: ColumnTypes.boolean,
    filterOptions: [
      {
        id: booleanType.TRUE,
        label: "True",
      },
      {
        id: booleanType.FALSE,
        label: "False",
      },
    ],
  },
  trackTitle: {
    width: 200,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
    cellRenderer: (rowData) => {
      return <span><strong>{HighlightedText(rowData, trackFields.trackTitle.id)}</strong></span>
    }
  },
  isrc: {
    width: 90,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.identity,
  },
  iswc: {
    width: 90,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.identity,
  },
  prs: {
    width: 60,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.identity,
  },
  prodName: {
    width: 160,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  catNo: {
    width: 100,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.identity,
  },
  licenseStatus: {
    width: 110,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    type: ColumnTypes.elasticString,
  },
  alternativeTitle: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  musicOrigin: {
    width: 90,
    alignHeader: JustificationTypes.center,
    alignCell: JustificationTypes.left,
    type: ColumnTypes.options,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    filterOptions: [
      {
        id: OriginTypes.commercial,
        label: i18next.t(`modules.originLables.commercial`),
      },
      {
        id: OriginTypes.library,
        label: i18next.t(`modules.originLables.library`),
      },
      {
        id: OriginTypes.commissioned,
        label: i18next.t(`modules.originLables.commissioned`),
      },
      { id: OriginTypes.live, label: i18next.t(`modules.originLables.live`) },
    ],
  },
  musicOriginSubIndicator: {
    width: 110,
    alignHeader: JustificationTypes.center,
    alignCell: JustificationTypes.left,
    type: ColumnTypes.options,
    isFilterable: true,
    isSortable: true,
    filterOptions: [
      {
        id: SubOriginTypes.Mcps,
        label: i18next.t(`modules.subOriginLables.Mcps`),
      },
      {
        id: SubOriginTypes.NonMcps,
        label: i18next.t(`modules.subOriginLables.NonMcps`),
      },
    ],
  },
  prodArtist: {
    width: 120,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  performer: {
    width: 100,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  lyricist: {
    width: 90,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  adaptor: {
    width: 120,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  administrator: {
    width: 120,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  prodTags: {
    width: 120,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  arranger: {
    width: 100,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  composer: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  publisher: {
    width: 110,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  originalPublisher: {
    width: 130,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  recordLabel: {
    width: 120,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  subLyricist: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  subAdaptor: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  subArranger: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  subPublisher: {
    width: 120,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  translator: {
    width: 80,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  position: {
    width: 60,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  duration: {
    width: 70,
    alignHeader: JustificationTypes.left,
    alignCell: JustificationTypes.left,
    type: ColumnTypes.number,
    isFilterable: true,
    isSortable: true,
    discretable: true,
  },
  arid: {
    width: 100,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.number,
  },
  prodRelease: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.datetime,
  },
  dateCreated: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isSortable: false,
    isFilterable: false,
    type: ColumnTypes.datetime,
  },
  dateLastEdited: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isSortable: false,
    isFilterable: false,
    type: ColumnTypes.datetime,
  },
  libName: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  prodYear: {
    width: 100,
    alignHeader: JustificationTypes.center,
    alignCell: JustificationTypes.right,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.number,
  },
  prodNumberOfDiscs: {
    width: 110,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.number,
  },
  prodDiscNr: {
    width: 110,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  prodNotes: {
    width: 120,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    type: ColumnTypes.elasticString,
  },
  trackVersionTitle: {
    width: 60,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  subIndex: {
    width: 100,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  instruments: {
    width: 100,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.tag,
  },
  bpm: {
    width: 50,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.number,
  },
  tempo: {
    width: 50,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.number,
  },
  genres: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.tag,
  },
  moods: {
    width: 90,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.tag,
  },
  styles: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.tag,
  },
  keywords: {
    width: 150,
    alignHeader: JustificationTypes.center,
    isFilterable: true,
    isSortable: true,
    discretable: true,
    type: ColumnTypes.tag,
  },
  trackNotes: {
    width: 100,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  wsId: {
    width: 180,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.identity,
  },
  wsName: {
    width: 180,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  prsWorkTunecode: {
    width: 180,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.identity,
  },
  prsWorkTitle: {
    width: 180,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  prsWorkWriters: {
    width: 180,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  prsWorkPublishers: {
    width: 180,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.elasticString,
  },
  prsSearchDateTime: {
    width: 180,
    alignHeader: JustificationTypes.center,
    isSortable: true,
    isFilterable: true,
    discretable: true,
    type: ColumnTypes.datetime,
  },
  player: {
    width: 20,
    alignHeader: JustificationTypes.center,
    alignCell: JustificationTypes.center,
    type: ColumnTypes.player,
    tablePageType: PageTableType.SEARCH_TRACKS,
  },
  menu: {
    width: 30,
    alignHeader: JustificationTypes.center,
    alignCell: JustificationTypes.center,
    type: ColumnTypes.menu,
    sticky: ColumnStickyTypes.right,
    tablePageType: PageTableType.SEARCH_TRACKS,
  },
};


