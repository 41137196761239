export const ContentAlertType = {
    TRACK : 1,
    ALBUM : 2
}

export const ContentAlertTypeList = [
    {id : ContentAlertType.TRACK, label: 'Track'},
    {id : ContentAlertType.ALBUM, label: 'Album'},
]

export const ContentAlertIssueType = {
    METADATA : 1,
    AUDIO : 2
}

export const ContentAlertIssueTypeList = [
    {id : ContentAlertIssueType.METADATA, label: 'Wrong Metadata'},
    {id : ContentAlertIssueType.AUDIO, label: 'Wrong Audio'},
]


export const ContentAlertStatus = {
    ALERTED : 1,
    RESOLVED : 2
}

export const ContentAlertStatusColors = {
    ALERTED : '#B22222',
    RESOLVED : '#00A300'
}
export const ContentAlertStatusList = [
    {id : ContentAlertStatus.ALERTED, label: 'Alerted', color : ContentAlertStatusColors.ALERTED},
    {id : ContentAlertStatus.RESOLVED, label: 'Resolved', color : ContentAlertStatusColors.RESOLVED},
]

export const ContentAlertPreview = {
    METADATA : 0,
    ALERT_DETAILS : 1,
}